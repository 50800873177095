import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from './isomorphic-layout-effect.mjs';

function useMountedRef() {
  const mounted = useRef(true);
  useIsomorphicLayoutEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  return mounted;
}

export { useMountedRef };
