function lengthMoreThan(length) {
  return input => input.length > length;
}
function lengthLessThan(length) {
  return input => input.length < length;
}
function isPositiveIntegerString(input) {
  return input !== '' && (input.match(/[^0-9]/g) || []).length === 0;
}
function isPositiveNumericString(input) {
  return input !== '' && (input.match(/[^0-9.,]/g) || []).length === 0;
}
function isNumericString(input) {
  return input !== '' && (input.match(/[^0-9.,-]/g) || []).length === 0;
}
function isEmpty(input) {
  return input === null || input === undefined || input.length === 0;
}
function isEmptyString(input) {
  return input === null || input === undefined || input.trim().length < 1;
}
function notEmpty(input) {
  return not(isEmpty)(input);
}
function notEmptyString(input) {
  return not(isEmptyString)(input);
}
function notNumericString(input) {
  return not(isNumericString)(input);
}

function not(fn) {
  return (...args) => !fn(...args);
}

export { isEmpty, isEmptyString, isNumericString, isPositiveIntegerString, isPositiveNumericString, lengthLessThan, lengthMoreThan, notEmpty, notEmptyString, notNumericString };
